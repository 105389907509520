// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/damai/jenkins/workspace/browser-build-portal-statics/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "团队",
        "locale": "团队",
        "key": "team",
        "path": "/team/:teamId",
        "routes": [
          {
            "path": "/team/:teamId",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeamIndex' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/TeamIndex'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "文件预览",
            "locale": "文件预览",
            "path": "/team/:teamId/preview",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FilePreview' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/FilePreview'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "购买云主机",
            "locale": "购买云主机",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "path": "/team/:teamId/ipMarket",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IpMarket' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/IpMarket'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "首页",
            "locale": "首页",
            "path": "/team/:teamId/dashboard",
            "key": "dashboard",
            "needSpecialRole": true,
            "className": "shouye_24",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Dashboard'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "name": "分身",
            "locale": "分身",
            "path": "/team/:teamId/shopManage/:type?/:shopId?",
            "key": "shopManage",
            "className": "chrome_outline",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ShopManage' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/ShopManage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "routes": [
              {
                "name": "分身详情",
                "locale": "分身详情",
                "path": "/team/:teamId/shopManage/:type/:shopId",
                "exact": true
              }
            ]
          },
          {
            "name": "手机",
            "locale": "手机",
            "path": "/team/:teamId/phoneManage/:resourceType?/:resourceId?",
            "key": "phoneManage",
            "className": "yidongwangluoIP_24",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Phone' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Phone'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "name": "手机",
            "locale": "手机",
            "path": "/team/:teamId/phonePopup/:phoneId",
            "key": "phonePopup",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PhonePopup' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/PhonePopup'), loading: LoadingComponent}),
            "layout": false,
            "hideInMenu": true,
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "name": "IP地址",
            "locale": "IP地址",
            "path": "/team/:teamId/ipManage/:type?/:ipId?",
            "key": "ipManage",
            "className": "IP_24",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IpManage' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/IpManage'), loading: LoadingComponent}),
            "functionCode": "IP_LIST",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "routes": [
              {
                "name": "IP详情",
                "locale": "IP详情",
                "path": "/team/:teamId/ipManage/:type/:ipId",
                "exact": true
              }
            ]
          },
          {
            "name": "流程编辑",
            "locale": "流程编辑",
            "path": "/team/:teamId/rpa/flow/:flowId",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rpa__FlowDetail' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Rpa/FlowDetail'), loading: LoadingComponent}),
            "functionCode": "RPA_CREATE_DELETE",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "layout": false,
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/team/:teamId/rpa/popup/task/:taskId",
            "layout": false,
            "name": "流程任务",
            "locale": "流程任务",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rpa__TaskPopup' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Rpa/TaskPopup'), loading: LoadingComponent}),
            "extract": true,
            "exact": true
          },
          {
            "name": "自动化",
            "locale": "自动化",
            "path": "/team/:teamId/rpa/:type?/:taskId?",
            "key": "rpa",
            "className": "jiqiren_24",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rpa' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Rpa'), loading: LoadingComponent}),
            "functionCode": "RPA_LIST",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "routes": [
              {
                "name": "流程管理",
                "locale": "流程管理",
                "path": "/team/:teamId/rpa/flow",
                "exact": true
              },
              {
                "name": "流程管理",
                "locale": "流程管理",
                "path": "/team/:teamId/rpa/flowGroup/:groupId",
                "exact": true
              },
              {
                "name": "流程计划",
                "locale": "流程计划",
                "path": "/team/:teamId/rpa/schedule",
                "exact": true
              },
              {
                "name": "流程任务",
                "locale": "流程任务",
                "path": "/team/:teamId/rpa/task",
                "exact": true
              },
              {
                "name": "任务详情",
                "locale": "任务详情",
                "path": "/team/:teamId/rpa/task/:taskId",
                "exact": true
              },
              {
                "name": "内置到浏览器的流程",
                "locale": "内置到浏览器的流程",
                "path": "/team/:teamId/rpa/browserFlow",
                "exact": true
              },
              {
                "name": "Open API",
                "locale": "Open API",
                "path": "/team/:teamId/rpa/openapi",
                "exact": true
              }
            ]
          },
          {
            "name": "插件",
            "locale": "插件",
            "path": "/team/:teamId/extension/:type?/:id?",
            "key": "extension",
            "className": "chajian_24",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Extension' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Extension'), loading: LoadingComponent}),
            "functionCode": "EXTENSION_LIST",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/team/:teamId/rpaTaskMonitor/:taskId",
            "layout": false,
            "name": "实时查看",
            "locale": "实时查看",
            "hideInMenu": true,
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rpa__TaskDetail__Monitor' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Rpa/TaskDetail/Monitor'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "费用",
            "locale": "费用",
            "key": "costManage",
            "className": "feiyong_24",
            "path": "/team/:teamId/costManage/:type?",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cost' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Cost'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "name": "团队",
            "locale": "团队",
            "key": "teamManage",
            "className": "tuandui_24",
            "path": "/team/:teamId/teamManage/:action?",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeamManage' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/TeamManage'), loading: LoadingComponent}),
            "functionCode": "TEAM_MANAGE",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "routes": [
              {
                "name": "成员管理",
                "locale": "成员管理",
                "path": "/team/:teamId/teamManage/users/:departmentId?",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeamManage__UserManage' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/TeamManage/UserManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "团队设置",
                "locale": "团队设置",
                "path": "/team/:teamId/teamManage/setting",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeamManage__TeamSetting' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/TeamManage/TeamSetting'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "角色权限",
                "locale": "角色权限",
                "path": "/team/:teamId/teamManage/access",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeamManage__AuthPane' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/TeamManage/AuthPane'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "成员审核",
                "locale": "成员审核",
                "path": "/team/:teamId/teamManage/audit/:auditStatus?",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeamManage__TeamAudit' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/TeamManage/TeamAudit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "安全设置",
                "locale": "安全设置",
                "path": "/team/:teamId/teamManage/secure/:type?",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TeamManage__TeamSecure' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/TeamManage/TeamSecure'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "团队云盘",
                "locale": "团队云盘",
                "path": "/team/:teamId/teamManage/yunpan/:type?/:path?",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Yunpan' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Yunpan'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "操作日志",
                "locale": "操作日志",
                "path": "/team/:teamId/teamManage/logs/:logType?",
                "className": "rizhi_24",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logs' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Logs'), loading: LoadingComponent}),
                "routes": [
                  {
                    "name": "分身访问日志",
                    "locale": "分身访问日志",
                    "path": "/team/:teamId/teamManage/logs/shopVisitLogs",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logs__ShopVisitLogs' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Logs/ShopVisitLogs'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "分身管理日志",
                    "locale": "分身管理日志",
                    "path": "/team/:teamId/teamManage/logs/shopManageLogs",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logs__ShopManageLogs' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Logs/ShopManageLogs'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "IP日志",
                    "locale": "IP日志",
                    "path": "/team/:teamId/teamManage/logs/ipLogs",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logs__IpLogs' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Logs/IpLogs'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "IP流量日志",
                    "locale": "IP流量日志",
                    "path": "/team/:teamId/teamManage/logs/ipTrafficLogs",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logs__IpTrafficLogs' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Logs/IpTrafficLogs'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "团队管理日志",
                    "locale": "团队管理日志",
                    "path": "/team/:teamId/teamManage/logs/teamManageLogs",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logs__TeamManageLogs' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Logs/TeamManageLogs'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "成员登录日志",
                    "locale": "成员登录日志",
                    "path": "/team/:teamId/teamManage/logs/memberLoginLogs",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Logs__MemberLoginLogs' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Logs/MemberLoginLogs'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "hideInMenu": true,
                "redirect": "/team/:teamId/teamManage/setting",
                "exact": true
              }
            ]
          },
          {
            "path": "/team/:teamId/record/shop/:shopId/session/:sessionId",
            "layout": false,
            "name": "录像",
            "locale": "录像",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Record' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Record'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "实时监管",
            "locale": "实时监管",
            "path": "/team/:teamId/watch/shop/:shopId/session/:sessionId",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Watcher' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Watcher'), loading: LoadingComponent}),
            "functionCode": "SHOP_AUTHORIZE",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "layout": false,
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/team/:teamId/shopLandPage/:shopId",
            "layout": false,
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__browser__ShopLandPage' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/browser/ShopLandPage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/team/:teamId/newTab/:shopId",
            "layout": false,
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__browser__NewTabPage' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/browser/NewTabPage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/team/:teamId/accessDeny/:shopId",
            "layout": false,
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__browser__AccessDeny' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/browser/AccessDeny'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/team/:teamId/alipay/:orderId",
            "layout": false,
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cost__AlipayPay' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Cost/AlipayPay'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "轨迹",
            "locale": "轨迹",
            "path": "/team/:teamId/tracks/:resourceType/:resourceId",
            "layout": false,
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tracks' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Tracks'), loading: LoadingComponent}),
            "functionCode": [
              "SHOP_AUTHORIZE",
              "IP_CONFIG"
            ],
            "exact": true
          },
          {
            "path": "/team/:teamId/runTask/:flowId",
            "layout": false,
            "name": "执行流程",
            "locale": "执行流程",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/team'), loading: LoadingComponent})],
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RunFlow' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/RunFlow'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/fingerprint/token/:token",
        "layout": false,
        "name": "浏览器指纹采集器",
        "locale": "浏览器指纹采集器",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Fingerprint' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Fingerprint'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/wx/loginStatus/:sessionId",
        "layout": false,
        "name": "微信登录",
        "locale": "微信登录",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Wechat__LoginStatus' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Wechat/LoginStatus'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/wx/message/:messageId",
        "layout": false,
        "name": "花漾RPA消息",
        "locale": "花漾RPA消息",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Wechat__Message' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Wechat/Message'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/rpaCaptcha/token/:token",
        "layout": false,
        "name": "输入验证码",
        "locale": "输入验证码",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RpaCaptcha' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/RpaCaptcha'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/rpaSelectorPicker/:flowId",
        "layout": false,
        "name": "元素捕获器",
        "locale": "元素捕获器",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RpaSelectorPicker' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/RpaSelectorPicker'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/shortcut/:token",
        "layout": false,
        "name": "分身",
        "locale": "分身",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Shortcut' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Shortcut'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/windowSyncToolbox",
        "layout": false,
        "name": "窗口管理器",
        "locale": "窗口管理器",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WindowSyncToolbox' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/WindowSyncToolbox'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "购买云主机",
        "locale": "购买云主机",
        "path": "/ipMarket",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IpMarket' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/IpMarket'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/result/scan/:verifyId",
        "layout": false,
        "name": "扫码结果页",
        "locale": "扫码结果页",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Result' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Result'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/result/oauth",
        "layout": false,
        "name": "授权结果",
        "locale": "授权结果",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OauthResult' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/OauthResult'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/myProfile",
        "layout": false,
        "name": "我获得的奖励",
        "locale": "我获得的奖励",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyInviteCode' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/MyInviteCode'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/login",
        "layout": false,
        "name": "登录",
        "locale": "登录",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserLogin__Login' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/UserLogin/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/register",
        "layout": false,
        "name": "注册",
        "locale": "注册",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserLogin__Register' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/UserLogin/Register'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/findPassword",
        "layout": false,
        "name": "找回密码",
        "locale": "找回密码",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserLogin__FindPassword' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/UserLogin/FindPassword'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/invite/wechat/:inviteCode",
        "layout": false,
        "name": "邀请注册",
        "locale": "邀请注册",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserLogin__Invite__ByQrcode' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/UserLogin/Invite/ByQrcode'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/invite/:inviteCode",
        "layout": false,
        "name": "邀请注册",
        "locale": "邀请注册",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserLogin__Invite' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/UserLogin/Invite'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/link/invite-join-team/:code",
        "layout": false,
        "name": "团队邀请",
        "locale": "团队邀请",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Link__InviteJoinTeam' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Link/InviteJoinTeam'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/selectTeam",
        "layout": false,
        "name": "选择团队",
        "locale": "选择团队",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserLogin__SelectTeam' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/UserLogin/SelectTeam'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/systemPref",
        "layout": false,
        "name": "偏好设置",
        "locale": "偏好设置",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SystemPref' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/SystemPref'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/aboutRuntime",
        "layout": false,
        "name": "关于",
        "locale": "关于",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AboutRuntime' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/AboutRuntime'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/modal",
        "layout": false,
        "name": "请确认",
        "locale": "请确认",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Modal' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/Modal'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/",
        "hideInMenu": true,
        "redirect": "/login",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/damai/jenkins/workspace/browser-build-portal-statics/src/pages/404'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
