import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { useOrder } from '@/components/Sort/SortDropdown';
import { Order } from '@/components/Sort/SortTitle';
import { sendAsync } from '@/utils/ElectronUtils';
import { getTeamIdFromUrl } from '@/utils/utils';

type Bounds = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export interface Display {
  id: number;
  label: string;
  bounds: Bounds;
  workArea: Bounds;
  size: Pick<Bounds, 'width' | 'height'>;
  workAreaSize: Pick<Bounds, 'width' | 'height'>;
}

export type Session = {
  teamId: number;
  sessionId: number;
  rpaFlowId?: number;
  rpaTaskId?: number;
  rpaPreview?: boolean;
  shop: Pick<API.ShopDetailVo, 'id' | 'name' | 'description' | 'platform' | 'stateless'>;
  startTime: number;
  primary?: boolean;
};

export default () => {
  const [displays, setDisplays] = useState<Display[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [selectedRows, setSelectedRows] = useState<Session[]>([]);
  const [controlled, setControlled] = useState(false);
  const [master, setMaster] = useState<number>();
  const [follows, setFollows] = useState<number[]>([]);
  const { order, changeOrder } = useOrder({ key: 'startTime', ascend: true }, 'win-sync-shop-list');

  const getSessionsSorted = useCallback(
    (_order: Order, ss: Session[]) => {
      let _sessions = [...ss];
      if (_order.key === 'custom') return _sessions;
      if (_order.key === 'name') {
        _sessions = _.sortBy(_sessions, (item) => item.shop.name);
      } else if (_order.key === 'startTime') {
        _sessions = _.sortBy(_sessions, (item) => item.startTime);
      } else if (_order.key === 'status') {
        const getSortValue = (s: Session) => {
          if (s.sessionId === master) {
            return 1;
          }
          if (follows.includes(s.sessionId)) {
            return 2;
          }
          return 3;
        };
        _sessions = _sessions.sort((a, b) => {
          return getSortValue(a) - getSortValue(b);
        });
      }
      if (!_order.ascend) {
        _sessions = _sessions.reverse();
      }
      return _sessions;
    },
    [follows, master],
  );

  const sessionsSorted = useMemo(() => {
    return getSessionsSorted(order, sessions);
  }, [getSessionsSorted, order, sessions]);

  const getSelectedDisplay = useCallback(
    (layout: 'grid' | 'diagonal') => {
      if (displays.length === 0) {
        return undefined;
      }
      const { displayId } = JSON.parse(
        localStorage.getItem(`browser.sync.align.${layout}`) ?? '{}',
      );
      const display = displays.find((d) => d.id === displayId);
      if (!display) {
        return displays[0];
      }
      return display;
    },
    [displays],
  );

  const calcSize = useCallback(
    (layout: 'grid' | 'diagonal') => {
      const selectedDisplay = getSelectedDisplay(layout);
      if (!selectedDisplay) {
        return {
          width: 640,
          height: 400,
        };
      }
      // 计算窗口大小
      const {
        columns = 3,
        left = 0,
        top = 0,
        hs = 0,
        vs = 0,
      } = JSON.parse(localStorage.getItem(`browser.sync.align.${layout}`) ?? '{}');
      if (layout === 'grid') {
        // 宫格布局
        const width = Math.max(
          500,
          Math.floor((selectedDisplay.workAreaSize.width - left - hs * (columns - 1)) / columns),
        );
        const height = Math.max(
          300,
          Math.floor(
            (selectedDisplay.workAreaSize.height -
              top -
              vs * (Math.ceil(selectedRows.length / columns) - 1)) /
              Math.ceil(selectedRows.length / columns),
          ),
        );
        return {
          width,
          height,
        };
      }
      // 对角线布局
      const width = Math.max(
        500,
        Math.floor(selectedDisplay.workAreaSize.width - left - hs * (selectedRows.length - 1)),
      );
      const height = Math.max(
        300,
        Math.floor(selectedDisplay.workAreaSize.height - top - vs * (selectedRows.length - 1)),
      );
      return {
        width,
        height,
      };
    },
    [getSelectedDisplay, selectedRows.length],
  );

  const changeOrderAndNotify = useCallback(
    (_order: Order, _session?: Session[]) => {
      const _sessionsSorted = getSessionsSorted(_order, _session ?? sessions);
      changeOrder(_order);
      setTimeout(() => {
        sendAsync('browser.sync.update.order', {
          teamId: getTeamIdFromUrl(),
          order: _sessionsSorted.map((s) => s.sessionId),
          orderBy: _order,
        });
      }, 100);
    },
    [changeOrder, getSessionsSorted, sessions],
  );

  return {
    displays,
    setDisplays,
    sessions,
    setSessions,
    order,
    changeOrder,
    changeOrderAndNotify,
    sessionsSorted,
    getSelectedDisplay,
    selectedRows,
    setSelectedRows,
    controlled,
    setControlled,
    master,
    setMaster,
    follows,
    setFollows,
    calcSize,
  };
};
