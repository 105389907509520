import I18N from '@/i18n';
import ReactDOM from 'react-dom';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import Lottie from 'react-lottie';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Resizable } from 're-resizable';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { EllipsisOutlined } from '@ant-design/icons';
import styles from './index.less';
import colors from '@/style/color.less';
import buttonStyles from '@/style/button.less';
import aiLoadingImg from '@/components/AiChat/lottie-assets/ai-orb.json';
import recordingImg from '@/components/AiChat/lottie-assets/recording-bubble.json';
import IconFontIcon from '@/components/Common/IconFontIcon';
import ColoursIcon from '@/components/Common/ColoursIcon';
import {
  aiConversationByConversationIdMessagesGet,
  aiConversationFindConversationGet,
  aiConversationMessageByMessageIdDelete,
  aiConversationConversationByConversationIdDelete,
  aiConversationCancelChatPut,
} from '@/services/api-AIChatAPI/ConversationController';
import { useRequest } from '@@/plugin-request/request';
import EmptyView from '@/components/Common/EmptyView';
import { useModel } from '@@/plugin-model/useModel';
import useAudioRecorder from '@/hooks/useAudioRecorder';
import useAiChatAction from '@/hooks/useAiChatAction';
import { getApiUrl, SkipErrorNotifyOption } from '@/utils/utils';
import MarkdownView from '@/components/Common/MarkdownView';
import RetinaImage from '@/components/Common/RetinaImage';
import { openInNewWindow, openOfficialSiteByAppWindow } from '@/utils/pageUtils';
import DMConfirm from '@/components/Common/DMConfirm';
import ajaxEventClient from '@/utils/AjaxEventClient';
import { getJwt } from '@/utils/jwt';

const PAGE_SIZE = 15;

interface ConversationProps {
  type: 'helper' | 'rpa_editor' | 'shop';
  resourceId?: number;
  emptyView?: ReactNode;
  close: () => void;
}

interface AiChatProps {
  type: 'helper' | 'rpa_editor' | 'shop';
  resourceId?: number;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  visible: boolean;
  title?: ReactNode;
  emptyView?: ReactNode;
  changeVisible: (visible: boolean) => void;
}

interface ContextMenuWrapProps {
  conversationId?: number;
  msgDto?: API.AiConversationMsgDto;
  onDelete?: () => void;
  onClean?: () => void;
}

const ContextMenuWrap: FC<ContextMenuWrapProps> = (props) => {
  const { conversationId, msgDto, children, onDelete, onClean } = props;
  const disabled = !msgDto;
  const childrenCopy = React.cloneElement(children, {
    onContextMenu: (e) => e.stopPropagation(),
  });
  return (
    <Dropdown
      destroyPopupOnHide
      trigger={['contextMenu']}
      overlay={
        <Menu className={styles.ctxMenu}>
          <Menu.Item
            key="copy"
            disabled={disabled || ['action', 'rpa_script'].includes(msgDto?.type ?? '')}
            icon={<IconFontIcon iconName="fuzhi_24" />}
            onClick={() => {
              copy(msgDto?.content ?? '');
              message.success(I18N.t('已复制到粘贴板'));
            }}
          >
            {I18N.t('复制')}
          </Menu.Item>
          <Menu.Item
            key="remove"
            icon={<IconFontIcon iconName="Close-Circle_24" />}
            disabled={disabled || msgDto?.temp}
            onClick={() => {
              onDelete?.();
            }}
          >
            {I18N.t('删除')}
          </Menu.Item>
          <Menu.Item
            key="clean"
            disabled={!conversationId}
            icon={<IconFontIcon iconName="Trash_24" />}
            onClick={() => {
              // 清空
              DMConfirm({
                title: I18N.t('确定要清空所有的聊天记录吗？'),
                content: I18N.t('聊天记录清空意味着会话的上下文也将被重置，请确认是否要继续'),
                zIndex: 1002,
                onOk: () => {
                  aiConversationConversationByConversationIdDelete({
                    conversationId: conversationId!,
                  }).then(() => {
                    onClean?.();
                  });
                },
              });
            }}
          >
            {I18N.t('清空')}
          </Menu.Item>
        </Menu>
      }
    >
      {childrenCopy}
    </Dropdown>
  );
};

const Conversation: FC<ConversationProps> = (props) => {
  const { type, resourceId, emptyView, close } = props;
  const [messages, setMessages] = useState<API.AiConversationMsgDto[]>([]);
  const [inputText, setInputText] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [recording, setRecording] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { emitter } = useModel('rpaEditor');
  const {
    data,
    loading,
    run: loadConversation,
  } = useRequest(() => aiConversationFindConversationGet({ conversationType: type, resourceId }));
  const { currentTeam } = useModel('currentTeam');
  const messagesRef = useRef(messages);
  const currentJobIdRef = useRef(0);
  const lastMsgId = useRef<number>();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const scrollRef = useRef<InfiniteScroll>(null);
  const eventSourceRef = useRef<EventSourcePolyfill | null>(null);
  const { getAction } = useAiChatAction();
  const { startRecording, stopRecording } = useAudioRecorder({
    onTextChange: (text) => {
      setInputText(text.replaceAll(/花样/g, I18N.t('花漾')));
      textAreaRef.current?.focus();
    },
  });

  // 加载历史消息
  const loadHistory = useCallback(() => {
    if (!data) return;
    aiConversationByConversationIdMessagesGet({
      conversationId: data.id!,
      lastMsgId: lastMsgId.current,
      limit: PAGE_SIZE,
    }).then((res) => {
      if (!res.data) return;
      const msgs = res.data;
      setHasMore(msgs.length >= PAGE_SIZE);
      setMessages(messages.concat(msgs));
      lastMsgId.current = msgs[msgs.length - 1]?.id;
    });
  }, [data, messages]);

  useEffect(() => {
    loadHistory();
    if (data) {
      textAreaRef.current?.focus();
    }
  }, [data]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (!data) return () => {};
    let hd: string;
    (async () => {
      hd = await ajaxEventClient.on('ai-chat-response', data.id, (msg: any) => {
        const arr = [...messagesRef.current];
        arr[0] = msg.prompt;
        arr.unshift(msg.completion);
        setMessages(arr);
        setWaiting(false);
        if (scrollRef.current?.getScrollableTarget()) {
          scrollRef.current.getScrollableTarget()!.scrollTop = 0;
        }
      });
    })();
    return () => {
      if (hd) {
        ajaxEventClient.un(hd);
      }
    };
  }, [data]);

  useEffect(() => {
    lastMsgId.current = undefined;
    setMessages([]);
    loadConversation();
  }, [currentTeam?.id, loadConversation, resourceId]);

  const stopCurrentJob = useCallback(() => {
    eventSourceRef.current?.close();
    // 取消 job
    aiConversationCancelChatPut({ chatJobId: currentJobIdRef.current }).then(() => {
      setWaiting(false);
    });
  }, []);

  const sendMsg = useCallback(() => {
    const userMsg = inputText.trim();
    if (!userMsg || waiting) return;
    setWaiting(true);
    setInputText('');
    // 立即显示自己的消息
    const newMessages: API.AiConversationMsgDto[] = [
      {
        temp: true,
        id: Date.now(),
        createTime: new Date().toString(),
        role: 'user',
        type: 'text',
        content: userMsg,
      } as API.AiConversationMsgDto,
    ].concat(messages);
    setMessages(newMessages);
    scrollRef.current!.getScrollableTarget()!.scrollTop = 0;
    eventSourceRef.current = new EventSourcePolyfill(
      getApiUrl(
        `/api/ai/conversation/${data!.id}/${
          type === 'rpa_editor' ? 'rpaChat' : 'chat'
        }/stream?content=${userMsg}`,
      ),
      {
        withCredentials: true,
        headers: {
          Authorization: getJwt(),
          'x-dm-team-id': currentTeam.id,
        },
      },
    );
    eventSourceRef.current.addEventListener('message', (e) => {
      const msg = JSON.parse(e.data);
      // console.log('sse msg', msg);
      if (msg.type === 'start') {
        currentJobIdRef.current = msg.taskId;
        const arr = [...messagesRef.current];
        arr.unshift({
          content: '',
          createTime: new Date().toString(),
          id: msg.taskId,
          role: 'assistant',
          type: 'text',
        });
        setMessages(arr);
        if (scrollRef.current?.getScrollableTarget()) {
          scrollRef.current.getScrollableTarget()!.scrollTop = 0;
        }
      } else if (msg.type === 'chat') {
        const arr = [...messagesRef.current];
        arr[0].content += msg.content;
        setMessages(arr);
        if (scrollRef.current?.getScrollableTarget()) {
          scrollRef.current.getScrollableTarget()!.scrollTop = 0;
        }
      } else if (msg.type === 'end') {
        // console.log('end msg', msg);
        eventSourceRef.current?.close();
        const arr = [...messagesRef.current];
        arr[0] = msg.data.completion;
        arr[1] = msg.data.prompt;
        setMessages(arr);
        if (scrollRef.current?.getScrollableTarget()) {
          scrollRef.current.getScrollableTarget()!.scrollTop = 0;
        }
        setWaiting(false);
      } else {
        console.log('unknown msg', msg);
      }
    });
  }, [currentTeam.id, data, inputText, messages, type, waiting]);

  const doStopRecording = useCallback(
    async (force = false) => {
      if (!force) {
        if (!recording) return;
      }
      setRecording(false);
      stopRecording();
    },
    [recording, stopRecording],
  );

  const doStartRecording = useCallback(async () => {
    if (recording) return;
    try {
      await startRecording();
      setRecording(true);
      const listener = () => {
        doStopRecording(true);
        document.removeEventListener('mouseup', listener);
      };
      document.addEventListener('mouseup', listener);
    } catch (e: any) {
      console.error(e);
      if (e.message === 'Permission denied') {
        message.error(I18N.t('麦克风已被禁用'));
      } else {
        message.error(e.message);
      }
      setRecording(false);
    }
  }, [doStopRecording, recording, startRecording]);

  const geneContent = useCallback(
    (msgDto: API.AiConversationMsgDto) => {
      const { role, content } = msgDto;
      // @ts-ignore
      let actionObj: { code: string; name: string; arguments?: any } = {};
      switch (msgDto.type) {
        case 'rpa_script':
          return (
            <span>
              {I18N.t(
                '根据您的要求，我们为您生成了一个流程框架，您可以点击查看并决定是否将这些流程节点保存至流程中',
              )}

              <a
                onClick={() => {
                  emitter.emit('show-ai-script-transform-modal', content);
                  close();
                }}
              >
                {I18N.t('【点击查看】')}
              </a>
            </span>
          );

        case 'action':
          try {
            actionObj = JSON.parse(content ?? '{}');
            return (
              <span>
                {I18N.t('您是否要执行操作：')}

                <a
                  onClick={() => {
                    getAction(actionObj.code, actionObj.arguments)();
                    close();
                  }}
                >
                  【{actionObj.name}】
                </a>
              </span>
            );
          } catch (e) {
            console.error(e);
          }
          return content;
        default:
          if (role === 'user') {
            return content;
          }
          if (!content) {
            return <EllipsisOutlined />;
          }
          return <MarkdownView text={content} />;
      }
    },
    [close, emitter, getAction],
  );

  const geneExtraContent = useCallback((msgDto: API.AiConversationMsgDto) => {
    const { content, extraInfo } = msgDto;
    let extraInfoObj: any = {};
    if (extraInfo) {
      try {
        extraInfoObj = JSON.parse(extraInfo);
      } catch (e) {}
    }
    // @ts-ignore
    let actionObj: { code: string; name: string; arguments?: any; extraInfo?: any } | null = null;
    if (msgDto.type === 'action') {
      try {
        actionObj = JSON.parse(content ?? '{}');
      } catch (e) {
        actionObj = null;
      }
      if (!actionObj) return null;
      if (actionObj.code === 'ai_create_rpa_flow' && actionObj.extraInfo) {
        let flows = actionObj.extraInfo.relatedFlows?.slice?.(0, 2);
        if (!flows) {
          flows = _.map(actionObj.extraInfo, (name, id) => ({ id, name }));
        }
        return (
          <Space size={4} style={{ fontSize: 14, color: '#999', flexWrap: 'wrap' }}>
            <ColoursIcon className="goumai_24" />
            {I18N.t('RPA流程市场推荐：')}
            {_.map(flows, ({ name, id }) => {
              return (
                <Button
                  key={id}
                  className={buttonStyles.successBtn}
                  size="small"
                  onClick={() => {
                    openOfficialSiteByAppWindow(`/store/rpa/All/${id}`);
                  }}
                >
                  {name}
                </Button>
              );
            })}
          </Space>
        );
      }
    } else if (msgDto.type === 'text') {
      if (extraInfoObj.links) {
        return (
          <Space size={4} style={{ fontSize: 14, color: '#999', flexWrap: 'wrap' }}>
            <ColoursIcon className="zhichi_24" />
            {I18N.t('参考资料：')}
            {_.map(extraInfoObj.links.slice(0, 2), (linkStr, idx) => {
              const regRes = /\[(.*)](.*)/.exec(linkStr);
              let label = `${I18N.t('参考资料')}${idx + 1}`;
              let url = linkStr;
              if (regRes) {
                // eslint-disable-next-line prefer-destructuring
                label = regRes[1];
                // eslint-disable-next-line prefer-destructuring
                url = regRes[2];
              }
              return (
                <Button
                  key={idx}
                  className={buttonStyles.successBtn}
                  size="small"
                  onClick={() => {
                    openInNewWindow(url);
                  }}
                >
                  {label}
                </Button>
              );
            })}
          </Space>
        );
      }
    }
    return null;
  }, []);

  const messageListCon = useMemo(() => {
    const con = messages.map((msgDto, idx) => {
      const { role, id, createTime, sensitive } = msgDto;
      let warnIcon = null;
      let extraContent = null;
      if (role === 'user' && sensitive) {
        warnIcon = (
          <Tooltip
            title={I18N.t(
              '您的发言涉及敏感话题，本次会话结束。请不要再次出现类似话题，否则将导致AI助手对您不可用。',
            )}
          >
            <IconFontIcon iconName="jinggao_24" color={colors.errorColor} />
          </Tooltip>
        );
      }
      if (['assistant', 'hy'].includes(role ?? '')) {
        extraContent = geneExtraContent(msgDto);
      }
      return (
        <div key={id} className={styles.conversationItemWrap}>
          <div
            className={classNames(
              styles.conversationItem,
              { [styles.user]: role === 'user' },
              { [styles.assistant]: role === 'assistant' },
            )}
          >
            <ContextMenuWrap
              conversationId={data?.id}
              msgDto={msgDto}
              onDelete={() => {
                const arr = [...messages];
                arr.splice(idx, 1);
                setMessages(arr);
                aiConversationMessageByMessageIdDelete({ messageId: id! });
              }}
              onClean={() => {
                lastMsgId.current = undefined;
                setMessages([]);
              }}
            >
              <div
                className={classNames(
                  styles.chatBubble,
                  { [styles.user]: role === 'user' },
                  { [styles.assistant]: role === 'assistant' },
                )}
              >
                {geneContent(msgDto)}
                {warnIcon}
              </div>
            </ContextMenuWrap>
            {extraContent && <div className={styles.extraContentRow}>{extraContent}</div>}
            <div className={styles.createTime}>
              {moment(new Date(createTime!)).format('MM-DD HH:mm:ss')}
            </div>
          </div>
          <div />
        </div>
      );
    });
    if (waiting) {
      con.unshift(
        <Row key="waiting" wrap={false} align="middle" justify="center">
          <Col>
            <Lottie
              width={42}
              height={42}
              isClickToPauseDisabled
              options={{
                animationData: aiLoadingImg,
              }}
            />
          </Col>
          <Col>
            <Tooltip
              title={
                <span style={{ fontSize: 12 }}>
                  {I18N.t(
                    '为了符合相关法律法规的要求，您的每一次询问我们都需要和AI服务交互多次，以避免涉黄、涉暴、涉政等敏感话题，由于交互次数过多导致响应较慢，敬请谅解；如果响应太慢，您可以点击停止生成，然后重新提问',
                  )}
                </span>
              }
            >
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                {I18N.t('正在为您生成答案...')}

                <a style={{ marginLeft: 8 }} onClick={stopCurrentJob}>
                  {I18N.t('停止生成')}
                </a>
              </Typography.Text>
            </Tooltip>
          </Col>
        </Row>,
      );
    } else if (messages.length === 0) {
      // 还没有产生过消息
      con.unshift(
        <div key="empty" className={styles.emptyWrap}>
          {emptyView ?? (
            <EmptyView
              description={I18N.t(
                '请注意，您在这里沟通的内容请避免任何涉黄、涉赌、涉恐、以及涉及政治等内容，否则将会影响AI助手的正常使用',
              )}
            />
          )}
        </div>,
      );
    }
    return con;
  }, [data?.id, geneContent, geneExtraContent, messages, waiting]);

  return (
    <>
      <ContextMenuWrap
        conversationId={data?.id}
        onClean={() => {
          lastMsgId.current = undefined;
          setMessages([]);
        }}
      >
        <div id="ai-chat-scroller" className={styles.body}>
          <InfiniteScroll
            inverse
            ref={scrollRef}
            next={loadHistory}
            style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'visible' }}
            loader={
              <div className={styles.conversationItemWrap} style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            }
            dataLength={messages.length}
            hasMore={hasMore}
            endMessage={
              messages.length > PAGE_SIZE ? (
                <Typography.Paragraph type="secondary" style={{ textAlign: 'center' }}>
                  {I18N.t('-没有更多消息啦-')}
                </Typography.Paragraph>
              ) : null
            }
            scrollableTarget="ai-chat-scroller"
          >
            {messageListCon}
          </InfiniteScroll>
        </div>
      </ContextMenuWrap>
      <Row className={styles.footer} align="bottom">
        <Col onMouseDown={doStartRecording} style={{ marginRight: 6 }}>
          <div className={styles.recordBtnWrap}>
            {recording ? (
              <Lottie
                options={{ animationData: recordingImg }}
                style={{ position: 'absolute', width: '130%', height: '130%' }}
              />
            ) : (
              <ColoursIcon className="maikefeng_24" style={{ width: 20, height: 32 }} />
            )}
          </div>
        </Col>
        <Col flex="1">
          <Input.TextArea
            ref={textAreaRef}
            disabled={!data}
            value={inputText}
            autoSize={{ minRows: 1, maxRows: 5 }}
            placeholder={I18N.t('有问题请问我...')}
            onChange={(e) => setInputText(e.target.value)}
            onPressEnter={(e) => {
              if (e.ctrlKey) {
                setInputText(`${inputText}\n`);
              } else if (!e.shiftKey) {
                e.preventDefault();
                sendMsg();
              }
            }}
          />
        </Col>
        <Col style={{ marginLeft: 8 }}>
          <Button disabled={loading || waiting} type="primary" onClick={sendMsg}>
            {I18N.t('发送')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

/**
 * AI助手
 * @constructor
 */
const AiChat: FC<AiChatProps> = (props) => {
  const {
    type,
    resourceId,
    top = 'auto',
    left = 'auto',
    right = 'auto',
    bottom = 'auto',
    visible,
    title,
    emptyView,
    changeVisible,
  } = props;
  const draggableRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({
    width: Number(localStorage.getItem('ai-chat-size-width') || '400'),
    height: Number(localStorage.getItem('ai-chat-size-height') || '600'),
  });
  const [zoomOut, setZoomOut] = useState(false);
  const latestSizeRef = useRef(size);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setZoomOut(true);
      }
      if (event.key === 'F1') {
        changeVisible(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [changeVisible]);

  useEffect(() => {
    if (zoomOut) {
      setTimeout(() => {
        setZoomOut(false);
        changeVisible(false);
      }, 200);
    }
  }, [changeVisible, zoomOut]);

  const onStart = useCallback(
    (event: DraggableEvent, uiData: DraggableData) => {
      if (!draggableRef?.current) {
        return;
      }
      const { clientWidth, clientHeight } = window?.document?.documentElement;
      const pageContainer = document.querySelector<HTMLDivElement>('#root > div');
      const targetRect = draggableRef.current.getBoundingClientRect();
      setBounds({
        left:
          -targetRect?.left +
          uiData?.x +
          (pageContainer?.offsetLeft ?? 0) +
          (top !== 'auto' ? 0 : 64 + 16),
        right:
          (pageContainer?.offsetLeft ?? 0) +
          (pageContainer?.offsetWidth ?? clientWidth) -
          (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y) - 16,
      });
    },
    [top],
  );

  const onStop = useCallback((event: DraggableEvent, uiData: DraggableData) => {
    setPosition({
      x: uiData.x,
      y: uiData.y,
    });
  }, []);

  const root = document.querySelector('#root > div');

  if (!visible || !root) {
    return null;
  }

  return ReactDOM.createPortal(
    <Draggable
      handle="#ai-header"
      bounds={bounds}
      position={position}
      onStart={onStart}
      onStop={onStop}
    >
      <div
        ref={draggableRef}
        className={classNames(styles.wrap, { [styles.zoomOut]: zoomOut })}
        style={{
          top,
          left,
          right,
          bottom,
          transformOrigin:
            top !== 'auto'
              ? `${latestSizeRef.current.width - 160}px top`
              : `left ${latestSizeRef.current.height - 200}px`,
        }}
      >
        <Resizable
          className={styles.resizeWrap}
          defaultSize={{
            width: Number(localStorage.getItem('ai-chat-size-width') || '400'),
            height: Number(localStorage.getItem('ai-chat-size-height') || '600'),
          }}
          handleStyles={{
            left: { cursor: 'ew-resize' },
            top: { cursor: 'ns-resize' },
            right: { cursor: 'ew-resize' },
            bottom: { cursor: 'ns-resize' },
            topLeft: { cursor: 'nwse-resize' },
            topRight: { cursor: 'nesw-resize' },
            bottomLeft: { cursor: 'nesw-resize' },
            bottomRight: { cursor: 'nwse-resize' },
          }}
          minWidth={370}
          minHeight={400}
          onResizeStop={(event, direction, elementRef, delta) => {
            latestSizeRef.current = {
              width: size.width + delta.width,
              height: size.height + delta.height,
            };
            setSize({ width: size.width + delta.width, height: size.height + delta.height });
            localStorage.setItem('ai-chat-size-width', String(size.width + delta.width));
            localStorage.setItem('ai-chat-size-height', String(size.height + delta.height));
          }}
        >
          <Row id="ai-header" className={styles.header} align="middle">
            <Col flex="1" style={{ textAlign: 'center' }}>
              <Space>
                <RetinaImage x1="/logo-24.png" x2="/logo-48.png" />
                {title ?? I18N.t('AI助手小漾同学')}
              </Space>
            </Col>
            <Col
              onClick={() => {
                setZoomOut(true);
              }}
            >
              <IconFontIcon iconName="jianqu_24" />
            </Col>
          </Row>
          <Conversation
            type={type}
            resourceId={resourceId}
            emptyView={emptyView}
            close={() => setZoomOut(true)}
          />
        </Resizable>
      </div>
    </Draggable>,
    root,
  );
};

const AiChatPopup = (props: {
  aiChatVisible: boolean;
  changeAiChatVisible: (v: boolean) => void;
}) => {
  const { aiChatVisible, changeAiChatVisible } = props;
  const [visible, setVisible] = useState(false);
  const { currentTeam } = useModel('currentTeam');

  useEffect(() => {
    let timer: any = 0;
    if (
      !aiChatVisible &&
      currentTeam?.id &&
      !localStorage.getItem(`hide-ai-chat-popup-${currentTeam.id}`)
    ) {
      timer = setTimeout(() => {
        setVisible(true);
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [aiChatVisible, currentTeam?.id]);

  useEffect(() => {
    if (aiChatVisible) {
      setVisible(false);
      localStorage.setItem(`hide-ai-chat-popup-${currentTeam?.id}`, '1');
    }
  }, [aiChatVisible, currentTeam?.id]);

  const close = useCallback(() => {
    setVisible(false);
    localStorage.setItem(`hide-ai-chat-popup-${currentTeam?.id}`, '1');
  }, [currentTeam?.id]);

  const root = document.querySelector('#root > div');

  if (!visible || !root) return null;

  return ReactDOM.createPortal(
    <div
      className={styles.aiChatPopupWrap}
      onClick={() => {
        changeAiChatVisible(true);
        close();
      }}
    >
      <div
        className={classNames({
          [styles.aiChatPopupText]: I18N.isCn(),
          [styles.aiChatPopupTextEn]: !I18N.isCn(),
        })}
      />
      <div
        className={styles.closeBtn}
        onClick={(e) => {
          e.stopPropagation();
          close();
        }}
      />
    </div>,
    root,
  );
};

export const AiChatMenuItem = () => {
  const [visible, setVisible] = useState(false);
  const { currentTeam } = useModel('currentTeam');

  useEffect(() => {
    setVisible(false);
  }, [currentTeam?.id]);
  return (
    <>
      <a className="menu-item" onClick={() => setVisible(!visible)}>
        <IconFontIcon iconName="ai" />
        <span className="menu-item-name">{I18N.t('AI助手')}</span>
      </a>
      <AiChatPopup aiChatVisible={visible} changeAiChatVisible={(v) => setVisible(v)} />
      <AiChat
        type="helper"
        left={64 + 16}
        bottom={30}
        visible={visible}
        changeVisible={(v) => setVisible(v)}
      />
    </>
  );
};

export default AiChat;
