import I18N from '@/i18n';
import { Button, Col, message, Row, Typography } from 'antd';
import React, { useState } from 'react';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import IconFontIcon from '@/components/Common/IconFontIcon';
import styles from './CreateTeam.less';
import { useForm } from 'antd/es/form/Form';
import DMConfirm from '@/components/Common/DMConfirm';
import buttonStyles from '@/style/button.less';
import { openByDefaultBrowser } from '@/utils/pageUtils';
import constants from '@/constants';
import DMModal from '@/components/Common/Modal/DMModal';
import _ from 'lodash';
import { teamPost } from '@/services/api-Account/TeamController';

interface Props {
  onUpdate?: (team: API.TeamDto) => void;
  close?: () => void;
  simple?: boolean;
}

export function tkTeamCreateConfirm(onCancel: () => void) {
  const _dialog = DMConfirm({
    width: 540,
    title: I18N.t('登录TIkTok店铺达人自动邀约与私域管理系统完成套餐的购买'),
    content: I18N.t(
      '系统会打开浏览器并登录TikTok达人自动邀约与私域管理系统，请您根据TikTok店铺数量购买相应的套餐；或者您可以跟在线客服联络，申请免费试用',
    ),
    footerBtns: [
      {
        content: (
          <Button
            onClick={() => {
              _dialog.destroy();
              openByDefaultBrowser(`${constants.productSite}/service/about`);
            }}
            className={buttonStyles.successBtn}
          >
            {I18N.t('在线客服')}
          </Button>
        ),
        key: 'onlineService',
      },
      {
        content: (
          <Button
            type={'primary'}
            onClick={() => {
              _dialog.destroy();
              openByDefaultBrowser(constants.tkSite);
            }}
          >
            {I18N.t('确定')}
          </Button>
        ),
        key: 'sure',
      },
      {
        content: (
          <Button
            onClick={() => {
              _dialog.destroy();
              onCancel();
            }}
          >
            {I18N.t('取消')}
          </Button>
        ),
        key: 'no',
      },
    ],
  });
}

const CreateTeamModal: React.FC<Props> = (props) => {
  const { onUpdate, simple } = props;
  const [visible, changeVisible] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [form] = useForm();
  const [error, setError] = useState<string>();
  const handleSubmit = async () => {
    if (submitting) return;
    setSubmitting(true);
    let values;
    try {
      values = await form.validateFields();
    } catch (newErrorInfo) {
      setSubmitting(false);
      return;
    }

    try {
      // 登录
      const rs = await teamPost({ ...values, teamType: 'normal' });
      if (rs.success) {
        setSubmitting(false);
        if (onUpdate) {
          onUpdate(rs.data!);
        }
        changeVisible(false);
        message.success(I18N.t('创建成功！'));
        return;
      }
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  return (
    <DMModal
      visible={visible}
      footer={null}
      headless
      wrapClassName={!simple && styles.borderRadiusWrapper}
      keyboard={false}
      maskClosable={false}
      onCancel={() => {
        changeVisible(false);
      }}
      bodyStyle={{ padding: simple ? '24px' : 0 }}
      width={560}
    >
      {!simple && <div className={styles.createTeamBanner}>{I18N.t('创建您的新团队')}</div>}
      <ProForm
        form={form}
        className={!simple ? styles.createTeamForm : false}
        initialValues={{
          teamName: '',
        }}
        submitter={{
          render: () => {
            return (
              <Row gutter={[8, 8]} wrap={false}>
                <Col flex={1}>
                  <Button block type={'primary'} onClick={handleSubmit} loading={submitting}>
                    {I18N.t('创建并切换到新团队')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      changeVisible(false);
                    }}
                  >
                    {I18N.t('取消')}
                  </Button>
                </Col>
              </Row>
            );
          },
        }}
        layout="horizontal"
      >
        {simple && (
          <div style={{ paddingTop: 12, paddingBottom: 12, color: '#878787' }}>
            {I18N.t('创建一个团队来管理您的资源：')}
          </div>
        )}
        <ProFormText
          name="teamName"
          fieldProps={{
            style: simple ? { fontSize: 14 } : {},
            size: 'large',
            prefix: !simple && (
              <IconFontIcon
                iconName="chuangjianxintuandui_24"
                className={isEmpty ? 'input-is-empty' : ''}
              />
            ),
            allowClear: false,
            autoFocus: true,
            onPressEnter: handleSubmit,
            onChange: (e) => {
              setIsEmpty(!e.target.value);
            },
          }}
          placeholder={I18N.t('请输入您的新团队名称')}
          rules={[
            {
              validator(_rule, value) {
                const val = _.trim(value);
                if (!val) {
                  setError(I18N.t('请输入团队名称！'));
                  return Promise.reject(new Error(I18N.t('请输入团队名称！')));
                }
                if (val.length < 1 || val.length > 100) {
                  setError(I18N.t('团队名称长度为1-100个字符！'));
                  Promise.reject(new Error(I18N.t('团队名称长度为1-100个字符！')));
                }
                setError(undefined);
                return Promise.resolve();
              },
            },
          ]}
          help={
            <div style={{ fontSize: 14, padding: '4px 0' }}>
              {error ? (
                <Typography.Text type={'danger'}>{error}</Typography.Text>
              ) : (
                <Typography.Text style={{ color: '#999' }}>
                  {I18N.t('好的名称，有利于增强您团队的辨识度')}
                </Typography.Text>
              )}
            </div>
          }
        />
        <div style={{ height: simple ? 24 : 100 }} />
      </ProForm>
    </DMModal>
  );
};
export default CreateTeamModal;
